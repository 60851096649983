<script setup lang="ts">
</script>
<template>
  <div
    class="tools"
    id="tools"
  >
    <div class="tools-content">
      <h2 class="center-title mobile">ИНСТРУМЕНТЫ</h2>
      <div class="tools-content-plates">
        <div class="tools-content-plates-plate">
          <img
            class="tools-content-plates-plate-image"
            src="@/assets/images/svg/trafic.svg"
            alt="">
          <div class="tools-content-plates-plate-text">
            <p class="tools-content-plates-plate-text-title">Большие обьёмы трафика</p>
            <p class="tools-content-plates-plate-text-description">
              JIGSAW ADS использует
              эффективную комбинацию
              инновационных систем
              социального трафика
            </p>
          </div>
        </div>
        <div class="tools-content-plates-plate">
          <img
            class="tools-content-plates-plate-image platforms"
            src="@/assets/images/svg/platforms.svg"
            alt="">
          <div class="tools-content-plates-plate-text">
            <p class="tools-content-plates-plate-text-title">Платформы размещения</p>
            <p class="tools-content-plates-plate-text-description">
              Позволяет привести
              только целевую
              аудиторию для наших
              заказчиков.
            </p>
          </div>
        </div>
        <div class="tools-content-plates-plate">
          <img
            class="tools-content-plates-plate-image adblock"
            src="@/assets/images/svg/adblock.svg"
            alt="">
          <div class="tools-content-plates-plate-text adblock">
            <p class="tools-content-plates-plate-text-title">Анти-Adblock</p>
            <p class="tools-content-plates-plate-text-description">
              Пользователи Adblock не
              привыкли к рекламе,
              поэтому ваши рекламные
              места привлекают все их
              внимание, что приводит к
              более высокому CTR.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tools {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(400px);
    @media (orientation: landscape){
      margin-top: get-vw(200px);
    }
    &-plates {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: get-vw(70px);
      margin-left: get-vw(-100px);
      width: 90%;
      @media (orientation: landscape){
        margin-top: get-vw(70px);
        margin-left: get-vw(-1px);
        flex-direction: row;
      }
      &-plate {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: get-vw(100px);
        @media (orientation: landscape){
          margin-top: get-vw(10px);
          flex-direction: column;
          width: get-vw(400px);
        }
        &-image {
          height: get-vw(700px);
          &.platforms {
            object-fit: contain;
            height: get-vw(700px);
            transform: scale(1.23);
          }
          &.adblock {
            object-fit: contain;
            width: auto;
            margin-left: get-vw(-150px);
            margin-bottom: get-vw(-100px);
            height: get-vw(700px);
          }
          @media (orientation: landscape){
            height: get-vw(300px);
            &.platforms {
              height: get-vw(300px);
            }
            &.adblock {
              margin-left: 0;
              margin-bottom: 0;
              height: get-vw(300px);
            }
          }
        }
        &-text {
          display: flex;
          flex-direction: column;
          color: #2D2D2D;
          margin-top: get-vw(35px);
          &.adblock {
            margin-left: get-vw(-100px);
            @media (orientation: landscape){
              margin-left: 0 !important;
            }
          }
          &-title {
            font-size: get-vw(80px);
            line-height: get-vw(75px);
            font-weight: 600;
            text-align: center;
            @media (orientation: landscape){
              font-size: get-vw(43px);
              line-height: get-vw(45px);
            }
          }
          &-description {
            margin-top: get-vw(55px);
            font-size: get-vw(65px);
            line-height: get-vw(69px);
            font-weight: 400;
            text-align: center;
            @media (orientation: landscape){
              margin-top: get-vw(55px);
              font-size: get-vw(30px);
              line-height: get-vw(35px);
            }
          }
        }
      }
    }
  }
}
</style>
